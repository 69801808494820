.my-selector-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.my-selector-container {
  width: 100%;
  font-family: "Plus Jakarta Sans", sans-serif;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  position: relative;
}

.my-selector-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 10px;
  background-color: white;
}

.my-selector-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80%;
  font-weight: 500;
  font-size: 14px;
  color: var(--textBlack);
}

.my-selected-options {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
}

.my-selected-item-box {
  background-color: #f0f0f0;
  padding: 2px 10px;
  border-radius: 5px;
  display: flex;
  font-weight: 400;
  font-size: 13px;
  align-items: center;
}

.my-selected-item {
  font-weight: 400;
  font-size: 13px;
}

.remove-item {
  cursor: pointer;
  margin-left: 15px;
  font-weight: bold;
}

.my-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 10px;
  background-color: white;
  border: 1px solid var(--border-color);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  animation: slide-down 0.3s ease-in-out;
  margin-top: 3px;
}

.my-dropdown-item {
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2px;
  border-radius: 7px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: var(--textBlack);
  transition: background-color 0.3s ease-in-out;
}

.my-dropdown-item:hover {
  background-color: #f0f0f0; /* Light gray on hover */
}

.my-dropdown-item.active {
  background-color: #f0f0f0; /* Light gray for active (selected) option */
}

.my-dropdown-item:last-child {
  border-bottom: none;
  margin-bottom: 2px;
}

/* Animation for the dropdown */
@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.my-dropdown-item.disabled {
  color: var(--border-color);
  text-decoration: line-through;
  pointer-events: none;
}
