.my-custom-toggle-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.my-button-group {
  display: flex;
  gap: 15px;
}

.my-toggle-button {
  padding-top: 7px;
  padding-bottom: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
}

.radio-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  position: relative;
}

.radio-circle.my-selected {
  border-color: #05b49b; /* Change border color when selected */
}

/* Inner filled dot when selected */
.radio-circle.my-selected::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #05b49b;
  border-radius: 50%;
  position: absolute;
}

/* Disabled state styling */
.radio-circle.disabled {
  border-color: #ccc;
}

.radio-circle.disabled::after {
  background-color: #ccc;
}

.my-toggle-button.disabled {
  color: grey;
  pointer-events: none;
}
